import { Box, Button, Container, Paper, Stack, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'


export default function Home() {
  const navigate = useNavigate()
  const location = useLocation()
  const [inputValue, setInputValue] = useState('')
  const [items, setItems] = useState<string[]>([])

  useEffect(() => {
    if (location.state?.items) {
      const newItems = location.state?.items as string[]
      setInputValue(newItems.join('\n'))
    }
  }, [location.state?.items])

  useEffect(() => {
    setItems(inputValue
      .split('\n')
      .map(item => item.trim())
      .filter(item => item)
    )
  }, [inputValue])

  const handleSortClick = () => {
    if (items.length < 2) {
      alert('Add at least two items')
      return
    }

    navigate('/sort', {
      state: { items: items }
    })
  }

  const buttonText = () => {
    const len = items.length
    if (len === 0) return 'Sort'
    if (len < 3) return `Sort ${len} items`


    const insertionSort = (len * len - 1) / 2
    const avlSort = Array.from({ length: len - 1 })
      .map((_, i) => Math.ceil(Math.log2(i + 2)))
      .reduce((a, b) => a + b, 0)

    const reduce = (1 - (avlSort / insertionSort)) * 100


    return `Sort ${len} items ${reduce.toFixed(0)}% easier and faster`
  }

  return (
    <Container maxWidth="md">
      <Stack gap={2}>

        <Stack direction='row' gap={4} alignItems='center'>
          <img src="/logo.svg" alt="isort logo" width={100} height={100} />
          <Stack>
            <Typography variant="h4">Sort your list</Typography>
            <Typography variant="h6">Effortlessly sort subjective lists with minimal effort.</Typography>
          </Stack>
        </Stack>

        <TextField
          label="Enter data to sort (one item per line)"
          variant="outlined"
          fullWidth
          multiline
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          rows={20}
        />

        <Box display="flex" justifyContent="center">
          <Button variant="contained" color="primary" size="large" onClick={handleSortClick}>{buttonText()}</Button>
        </Box>

        <Paper>
          <Stack padding={2}>
            <Typography variant="body1">
              isort helps you  sort your data by asking you to compare pairs of items, one at a time.
              This isn’t your typical alphabetical or numerical sorting; instead, it’s completely arbitrary, meaning <strong>you decide the order based on your preferences</strong>.
              It’s interactive because you make the choice for each pair. This approach is simple because <strong>it’s easier for people to decide between just two things</strong>.
              The tool is also fast because it uses a binary tree in the background, so <strong>it doesn’t have to compare every single item</strong> directly.
              And finally, isort is secure because <strong>everything is processed on your device</strong> – no data is sent anywhere else, keeping your information completely private.
            </Typography>
            {/* <Link href="#" underline="hover">Learn more</Link> */}
          </Stack>
        </Paper>
      </Stack>

    </Container>
  )
}
