type CompareCallback = (a: string, b: string) => Promise<number>
type ProgressCallback = (progress: number) => void

class TreeNode {
  value: string
  left: TreeNode | null = null;
  right: TreeNode | null = null;
  height: number = 1;

  constructor(value: string) {
    this.value = value
  }
}

class AVLTree {
  private root: TreeNode | null = null;
  private length = 0
  private memory = {} as { [key: string]: number }

  constructor(
    private compareCallback: CompareCallback,
    private comparisonProgress: (currentComparison:number, treeLength:number) => void,
  ) { }

  private getHeight(node: TreeNode | null): number {
    return node ? node.height : 0
  }

  private updateHeight(node: TreeNode): void {
    node.height = 1 + Math.max(this.getHeight(node.left), this.getHeight(node.right))
  }

  private rotateRight(y: TreeNode): TreeNode {
    const x = y.left!
    const T2 = x.right

    x.right = y
    y.left = T2

    this.updateHeight(y)
    this.updateHeight(x)

    return x
  }

  private rotateLeft(x: TreeNode): TreeNode {
    const y = x.right!
    const T2 = y.left

    y.left = x
    x.right = T2

    this.updateHeight(x)
    this.updateHeight(y)

    return y
  }

  private getBalance(node: TreeNode): number {
    return node ? this.getHeight(node.left) - this.getHeight(node.right) : 0
  }

  private async insertNode(node: TreeNode | null, value: string, currentComparison: number): Promise<TreeNode> {
    if (node === null) {
      return new TreeNode(value)
    }

    const comparison = await this.compare(value, node.value)
    this.comparisonProgress(++currentComparison, this.length)

    if (comparison < 0) {
      node.left = await this.insertNode(node.left, value, currentComparison)
    } else {
      node.right = await this.insertNode(node.right, value, currentComparison)
    }

    this.updateHeight(node)

    const balance = this.getBalance(node)

    if (balance > 1) {
      if (await this.compare(value, node.left!.value) < 0) {
        return this.rotateRight(node)
      } else {
        node.left = this.rotateLeft(node.left!)
        return this.rotateRight(node)
      }
    }

    if (balance < -1) {
      if (await this.compare(value, node.right!.value) > 0) {
        return this.rotateLeft(node)
      } else {
        node.right = this.rotateRight(node.right!)
        return this.rotateLeft(node)
      }
    }

    return node
  }

  private async compare(a: string, b: string): Promise<number> {
    const key = `'${a}':'${b}'`

    if (typeof this.memory[key] !== 'undefined') {
      return this.memory[key]
    }

    const result = await this.compareCallback(a, b)

    const reverseKey = `'${b}':'${a}'`

    this.memory[key] = result
    this.memory[reverseKey] = -result

    return result
  }

  async insert(value: string): Promise<void> {
    this.root = await this.insertNode(this.root, value, 0)
    this.length++
  }

  private inOrderTraversal(node: TreeNode | null, result: string[] = []): string[] {
    if (node !== null) {
      this.inOrderTraversal(node.left, result)
      result.push(node.value)
      this.inOrderTraversal(node.right, result)
    }
    return result
  }

  getSortedArray(): string[] {
    return this.inOrderTraversal(this.root)
  }
}

export async function sort(array: string[], compareCallback: CompareCallback, progressCallback?: ProgressCallback): Promise<string[]> {
  const reportProgress = (currentComparison:number, treeLength:number) => {
    if (!progressCallback) return

    progressCallback(calculateProgress(array.length, treeLength, currentComparison))
  }

  const tree = new AVLTree(compareCallback, reportProgress)

  for (let i = 0; i < array.length; i++) {
    await tree.insert(array[i])
  }

  return tree.getSortedArray()
}

function calculateProgress(arrayLength: number, alreadySorted: number, currentComparison: number) {
  const totalComparisons = Array.from({ length: arrayLength - 1 })
    .map((_, i) => Math.ceil(Math.log2(i + 2)))
    .reduce((a, b) => a + b, 0)

  const comparisonsSoFar = Array.from({ length: alreadySorted - 1 })
    .map((_, i) => Math.ceil(Math.log2(i + 2)))
    .reduce((a, b) => a + b, 0) + currentComparison

  const progress = (comparisonsSoFar / totalComparisons) * 100

  return progress
}