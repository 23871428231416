import { Box, Button, ButtonGroup, Card, CardActions, CardContent, Container, LinearProgress, Stack, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { sort } from '../domain/sort'

export default function Sort() {
  const [progress, setProgress] = useState<number>(0)
  const [currentPair, setCurrentPair] = useState<{ item1: string, item2: string } | null>(null)
  const comparisonPromiseResolveRef = useRef<any>(null) // Usando useRef para armazenar o callback

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const items = location.state?.items || []
    startSort(items)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === '1') {
        handleCompare(1)
      } else if (event.key === '2') {
        handleCompare(-1)
      } else if (event.key === '0') {
        handleCompare(0)
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [currentPair]) // Dependência do par atual

  const startSort = async (items: string[]) => {
    const compareCallback = async (a: string, b: string): Promise<number> => {
      setCurrentPair({ item1: a, item2: b })

      return new Promise<number>((resolve) => {
        comparisonPromiseResolveRef.current = resolve
      })
    }

    const result = await sort(items, compareCallback, progress => setProgress(progress))
    setProgress(100)

    navigate('/result', {
      state: { items: result }
    })
  }

  const handleCompare = (result: number) => {
    if (comparisonPromiseResolveRef.current) {
      comparisonPromiseResolveRef.current(result)
    }
  }

  const handleResetClick = () => {
    navigate('/')
  }

  const handleRestartClick = () => {
    navigate('/', {
      state: { items: location.state?.items }
    })
  }

  return (
    <Container maxWidth="md">
      <Stack gap={2}>

        <Box>
          <Typography variant="h4" align="center">
            Which one is <strong>greater</strong>?
          </Typography>
        </Box>
        <Box textAlign="center">
          {currentPair && (
            <Stack gap={3}>
              <Stack gap={3} direction={{ xs: 'column', md: 'row' }} alignItems='stretch'>
                <Card sx={{ flexGrow: 1 }}>
                  <CardContent>
                    <Typography variant='h6'>
                      {currentPair.item1}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button onClick={() => handleCompare(1)}>1 - This is greater one</Button>
                  </CardActions>
                </Card>

                <Card sx={{ flexGrow: 1 }}>
                  <CardContent>
                    <Typography variant='h6'>
                      {currentPair.item2}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button onClick={() => handleCompare(-1)}>2 - This is greater one</Button>
                  </CardActions>
                </Card>
              </Stack>
              <Stack gap={1}>
                <Button variant='outlined' size='small' onClick={() => handleCompare(0)}>0 - they are equal</Button>
              </Stack>
            </Stack>
          )}
        </Box>

        <Box>
          <LinearProgress variant="determinate" value={progress} />
        </Box>

        <Box display="flex" justifyContent="center">
          <ButtonGroup>
            <Button variant="outlined" color="primary" size="large" onClick={handleRestartClick}>Restart</Button>
            <Button variant="outlined" color="primary" size="large" onClick={handleResetClick}>Reset</Button>
          </ButtonGroup>
        </Box>

      </Stack >

    </Container >
  )
}
