import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import Home from './pages/home'
import Result from './pages/result'
import Sort from './pages/sort'

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sort" element={<Sort />} />
        <Route path="/result" element={<Result />} />
      </Routes>
    </Router>
  )
}
