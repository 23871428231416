import { ContentCopy } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Container, Paper, Stack, Typography } from '@mui/material'
import copyTextToClipboard from 'copy-text-to-clipboard'
import { useLocation, useNavigate } from 'react-router-dom'

export default function Result() {
  const location = useLocation()
  const navigate = useNavigate()

  const items = location.state.items ?? []

  const handleCopyClick = () => {
    copyTextToClipboard(items.join('\n'))
  }

  const handleResetClick = () => {
    navigate('/')
  }

  const handleRestartClick = () => {
    navigate('/', {
      state: { items }
    })
  }

  return (
    <Container maxWidth="md">
      <Stack gap={2}>

        <Typography variant="h4" align="center">
          Result
        </Typography>

        <Paper>
          <ul>
            {items.map((item: string, i: number) => <li key={i}>{item}</li>)}
          </ul>
        </Paper>

        <Box display="flex" justifyContent="center">
          <ButtonGroup>
            <Button variant="contained" color="primary" size="large" onClick={handleCopyClick}><ContentCopy /> Copy</Button>
            <Button variant="outlined" color="primary" size="large" onClick={handleRestartClick}>Restart</Button>
            <Button variant="outlined" color="primary" size="large" onClick={handleResetClick}>Reset</Button>
          </ButtonGroup>
        </Box>

      </Stack>

    </Container>
  )
}
